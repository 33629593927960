<template src="./fxtransactionsummary.html"></template>
<style scoped src="./fxtransactionsummary.css"></style>
<script>
import fxService from '@/services/FXServices.vue'
import fxConstants from '@/constants/FxConstants.vue'
import Loading from 'vue-loading-overlay'
import moment from 'moment'
import { requiredIf } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import jsonToExcel from 'vue-json-excel'
import vtpaginationVue from '../../vtable/vtpagination.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    components : {
        Loading, modal, jsonToExcel, vtpaginationVue
    },
    data() {
        return {
            searchBy: '',
            id:'',
            fxTransactionType : null,
            fxTransactionStatus : null,
            fxAccount: null,
            isManualRecord : null,
            fromDate: null,
            toDate : null,
            fxProviderNameList : JSON.parse(JSON.stringify(fxConstants.fxProvider)),
            fxTransactionStatusList : fxConstants.fxTransactionStatus,
            fxTransactionTypeList : fxConstants.fxTransactionType,
            fxAccountList : [],
            loaderVisible : false,
            fxTransactionSummary : [],
            errorMessages: applicationConstants.formsMessages,
            columns :['FXTransactionId','FXTranscationParentId','FXTransactionType','FXTransactionStatus','TransactionTime','SourceFXAccountId','Action'],
            options : {
                headings : {
                    TransactionTime : 'Transaction Time'
                },
                uniqueKey : 'FXTransactionId'
            },
            utils: utils,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: {
                'FX TransactionId': 'FXTransactionId',
                'FX Transaction Parent Id': 'FXTranscationParentId',
                'FX Transaction Type': 'FXTransactionType',
                'FX Transaction  Status': 'FXTransactionStatus',
                'Transaction Time': 'TransactionTime',
                'Source FX Account Id': 'SourceFXAccountId',
                'Source Amount':'SourceAmount',
                'Source Account Ending Balance':'SourceAccountEndingBalance',
                'Destination FX Account': 'DestinationFXAccountId',
                'Destination Amount': 'DestinationAmount',
                'Fx Buy Rate': 'FXBuyRate',
                'Is Manual Record': 'IsManualRecord',
                'Excuted by': 'ExecutedBy'
            },
            enableLazyLoading: applicationConstants.enableLazyLoading
        }
    },
    methods : {
        ...mapActions(['fetchFXTransactionSummary']),
        async searchFXTransaction() {
            try {
                let dateChecker = true, dateValidation = true, fDate = null, tDate = null
                let fxTransactionId = this.searchBy === '0' ? this.id : null
                let fxTransactionParentId = this.searchBy === '1' ? this.id : null
                this.fromDate = this.fromDate === '' ? null : this.fromDate
                this.toDate = this.toDate === '' ? null : this.toDate
                if (this.fromDate !== null && this.toDate !== null) {
                    fDate = utils.methods.LocalToUTC(moment(moment(this.fromDate)._d).format('MM-DD-YYYY hh:mm:ss'))
                    tDate = utils.methods.LocalToUTC(moment(moment(this.toDate)._d).format('MM-DD-YYYY hh:mm:ss'))
                    if (fDate > tDate) {
                        dateChecker = false
                        this.showAlert(null, 'From Date should be less than ToDate')
                    }
                } else if (this.fromDate !== null && this.toDate === null) {
                    this.$v.toDate.$touch()
                    dateValidation = false
                } else if (this.fromDate === null && this.toDate !== null) {
                    this.$v.fromDate.$touch()
                    dateValidation = false
                }
                if (this.searchBy !== '') {
                    this.$v.id.$touch()
                    if (this.$v.id.$invalid) {
                        return
                    }
                }
                if (dateChecker && dateValidation) {
                    this.loaderVisible = true
                    await this.fetchFXTransactionSummary({
                        FXProviderName: this.fxAccount === 'null' ? null : this.fxAccount,
                        FXTransacationId: fxTransactionId,
                        FxTransctionParentId: fxTransactionParentId,
                        FXTransactionStatus: this.fxTransactionStatus,
                        FXTransactionTimeFrom: fDate,
                        FXTransactionTimeTo: tDate,
                        FXTransactionType: this.fxTransactionType,
                        IsManual: this.isManualRecord
                    })
                    this.fxTransactionSummary = this.getFXTransactionSummary
                    this.enableLazyLoading ? this.$refs.paginationRef.selectedPage = 1 : null
                    fxService.methods.GetFXAccountList(this.fxAccount)
                        .then(response => {
                            this.fxAccountList = this.responseMapping(response.csFxGetAccountsResponse.FxAccountList.FXAccountListItem)
                        })
                        .catch(err => {
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                        .catch(err => {
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                }
                this.$v.$reset()
                this.loaderVisible = false
            } catch (error) {
                this.loaderVisible = false                
            }
        },
        responseMapping(data) {
            if(data) {
                data = Array.isArray(data) ? data : [data]
                const reciptient = (recipt) => {
                    Object.values(recipt).map((el, key) => {
                        const Objectkey = Object.keys(recipt)[key]
                        recipt[Objectkey] = el._text
                    })
                }
                data.map(reciptient)
            }else{
                data = []
            }   
            return data
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        clearAllFields(){
            this.searchBy = '',
            this.id = null,
            this.fxTransactionType = null,
            this.fxTransactionStatus = null,
            this.fxAccount = null,
            this.isManualRecord = null,
            this.fromDate = null,
            this.toDate = null
            this.$v.$reset()
        },
        viewFxTransaction(data) {
            try {
                data.FXTransactionId= data.FXTransactionId ? data.FXTransactionId : ''
                data.FXTransactionParentId = data.FXTransactionParentId ? data.FXTransactionParentId : ''
                this.$router.push({
                   name : 'FxTransaction',
                   params : {
                       fxtransactionid : data.FXTransactionId,
                       fxTransactionParentId : data.FXTransactionParentId,
                       SourceFXAccountId: data.SourceFXAccountId,
                       DestinationFXAccountId: data.DestinationFXAccountId,
                       fxTransactionSummaryFilterParams: {
                        searchBy : this.searchBy,
                        id : this.id,
                        fxTransactionType : this.fxTransactionType,
                        fxTransactionStatus : this.fxTransactionStatus,
                        fxAccount : this.fxAccount,
                        isManualRecord : this.isManualRecord,
                        fromDate : this.fromDate,
                        toDate : this.toDate
                       }
                    }
                })
            } catch (error) {
                //-
            }
        },
        exportTableData() {
            if(this.fxTransactionSummary) {
                return this.updateData(this.responseMapping(JSON.parse(JSON.stringify(this.fxTransactionSummary))))
            }
        },
        updateData(fxTransactionSummary) {
            fxTransactionSummary.forEach(item => {
                item.TransactionTime = utils.methods.UTCtoLocal(moment(moment(item.TransactionTime, 'MM/DD/YYYY hh:mm:ss A')._d).format('MM-DD-YYYY HH:mm:ss'))
                let srcFxAcc = this.fxAccountList.find(child => child.FXAccountId === item.SourceFXAccountId)
                let desFxAcc = this.fxAccountList.find(child => child.FXAccountId === item.DestinationFXAccountId)
                srcFxAcc ? item.SourceFXAccountId = srcFxAcc.FXProviderName + ' - CAD' : null
                desFxAcc ? item.DestinationFXAccountId = desFxAcc.FXProviderName + ' - ' + desFxAcc.Currency : null
            })
            return fxTransactionSummary
        }
    },
    computed: {
        ...mapGetters(['getFXTransactionSummary']),
        today: () => new Date(),
        fxTransactionTableData() {
            let fxTransactionSummary = []
            if (this.fxTransactionSummary) {
                if (this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    fxTransactionSummary = this.responseMapping(JSON.parse(JSON.stringify(this.fxTransactionSummary)).slice((selectedPage - 1) * 10, selectedPage * 10))
                } else {
                    fxTransactionSummary = this.responseMapping(JSON.parse(JSON.stringify(this.fxTransactionSummary)))
                }
                fxTransactionSummary = this.updateData(fxTransactionSummary)
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.fxTransactionSummary?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return fxTransactionSummary
        }
    },
    validations: {
        id:{
            required : requiredIf(function() {
                return this.searchBy !== '' 
            })
        },
        fromDate: {
            required: requiredIf(function() {
                return !this.fromDate && this.toDate
            })
        },
        toDate: {
            required: requiredIf(function() {
                return this.fromDate && !this.toDate
            })
        }
    },
    async created () {
        try {
            this.fxProviderNameList.map(fxProvider => {
                if(fxProvider.value === '' || fxProvider.value === null) {
                    fxProvider.text = '--Select--'
                    fxProvider.value = null
                }else{
                    fxProvider.value = fxProvider.text
                }
            })
            let data = this.$route.params.fxTransactionSummaryFilterParams
            if(data) {
                this.searchBy = data.searchBy ? data.searchBy : ''
                this.id = data.id ? data.id : ''
                this.fxTransactionType = data.fxTransactionType ? data.fxTransactionType : null
                this.fxTransactionStatus = data.fxTransactionStatus ? data.fxTransactionStatus : null,
                this.fxAccount =data.fxAccount? data.fxAccount : null,
                this.isManualRecord = data.isManualRecord ? data.isManualRecord : null,
                this.fromDate = data.fromDate ? data.fromDate : null
                this.toDate =data.toDate ? data.toDate : null
                this.searchFXTransaction() 
            } 
        } catch (error) {
            //
        }
    },
}
</script>